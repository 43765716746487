//import React, { useEffect, useState, useContext } from "react"; OTP 7825
import React, { useEffect, useMemo, useState, useContext } from "react";
import axios from "axios";
//import { Link } from "react-router-dom";
import "./Styles/clients.css";
//import { AuthLoginInfo } from "../AuthComponents/AuthLogin";
import Popup from "../Components/Popup";
import SearchBar from "../Components/SearchBar";
import Pagination from "../Components/Pagination";
import ReadMoreRoundedIcon from "@mui/icons-material/ReadMoreRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Switch from '@mui/material/Switch';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configData from "./config.json";
import ReconnectingWebSocket from 'reconnecting-websocket';
import { LTPContext } from "../App";

//import SuperSocket from "@shippr/supersocket";
//https://github.com/getshippr/supersocket
// SELECT distinct(account_id) FROM `crons` order by account_id asc;
// SELECT s.exchange_id,s.symbol,c.base_qty,c.buy_vwap,c.buy_below_21_sma,c.single_order,c.enable
// FROM crons as c
// LEFT JOIN stocks as s
// ON s.id = c.stock_id 
// WHERE c.account_id=1 and c.enable=1;

const customId = "toast-main";
const isws = true;

//var ws = new WebSocket(configData.SOCKET_URI);
//const ws = new SuperSocket(configData.SOCKET_URI);
if(isws === true) {
  var ws = new ReconnectingWebSocket(configData.SOCKET_URI);
}

function Strategy() {
  //const ctx = useContext(AuthLoginInfo);
  const [newOrderSubmitted, setNewOrderSubmitted] = useState(false);
  const [strategyData, setStrategyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonEditPopup, setEditButtonPopup] = useState(false);
  const [buttonViewPopup, setViewButtonPopup] = useState(false);
  const [logDetails, setLogDetails] = useState([{
    id:1,
    'strategy_name': 'S1',
    'descrp': '',
    'type': '',
    'created': ''
  }]);       
  //const [filterId, setFilterId] = useState("");
  const [strategyDetails, setStrategyDetails] = useState({});
  const [strategyAddDetails, setStrategyAddDetails] = useState({
    name: "",
    indice: "NIFTY",
    order_type: "limit_buy",
    expiry: "current_wk", 
    position_type:"buy",   
    entry_level: 0,
    qty: 0,
    tlslx: 0,                  
    tlsly: 0,                  
    stoploss: 0,
    target: 0,
    stoploss_level: 0,
    trailing_sl: 0,
    target_level: 0,              
    strike: 0,
    risk_reward: 1,
    risk_amount: 0,
    risk_measure: 'points',                 
    sstatus: 0, 
  });  

  const [filterOrders, setFilterOrders] = useState("");
  //#const [filterId, setFilterId] = useState("");
  const [filterActive, setFilterActive] = useState(1);

  // const [currentRecord, setRecord] = useState({});
  //const [lastField, setLastField] = useState('');
  //const [checkFileModified, setCheckFileModified] = useState(true);  
  const toastId = React.useRef(null);
  const [ltpData, setLtpData] = useState({});
  const [livePnl, setLivePnl] = useState(0);
  const [totalPnl, setTotalPnl] = useState(0);  
  const [stratgPnl, setStratgPnl] = useState({});
  const {globalLTP, setGlobalLTP} = useContext(LTPContext);
  const globalSettingName = 'global-setting'
  //const [checked, setChecked] = useState(false);

  //const socket = socketIO.connect('http://localhost:5000');   

  // const socket = io("ws://localhost:5000");
  // socket.on("change", () => {
  //   console.log('change event detected!');
  // });

  // const socket = new WebSocket("ws://localhost:5000")

  // // Connection opened
  // socket.addEventListener("open", event => {
  //   socket.send("Connection established")
  // });
  
  // Listen for messages
  // socket.addEventListener("change", event => {
  //   console.log("Change message from server ", event.data)
  // });  

  //  useEffect(() => {
  //   const socket = io("ws://localhost:5000");
  //   socket.on("change", () => {
  //     console.log('change event detected!');
  //     // axios
  //     //   .get("http://localhost:5000/strategy", { withCredentials: true })
  //     //   .then((res) => {
  //     //     if (res.data != null) {
  //     //       setStrategyData(res.data);
  //     //       setFilteredData(res.data);
  //     //     }
  //     //   });
  //   });
  // }, []);

  const notify = (toast_mess, ntype) => {
    //if(! toast.isActive(toastId.current)) {
      if(ntype === 'success') {
        toastId.current = toast.success(toast_mess, {
          position: "top-right",
          theme: "dark",
          toastId: customId,
          autoClose : 1000,
          newestOnTop: true
        });
      } else if (ntype === 'error') {
        toastId.current = toast.error(toast_mess, {
          position: "top-right",
          theme: "dark",
          toastId: customId,
          autoClose : 1000,
          newestOnTop: true
        });        
      } else {
        toastId.current = toast.success(toast_mess, {
          position: "top-right",
          theme: "dark",
          toastId: customId,
          autoClose : 1000,
          newestOnTop: true
        });
      }
    //}
  }

  useEffect(() => {
      setNewOrderSubmitted(false);
      axios
      .get(configData.BACKEND_URL+"/strategy", { withCredentials: true })
      .then((res) => {
        if (res.data != null) {
          setStrategyData(res.data);
          setFilteredData(res.data);
        }
      });

      axios.post(configData.BACKEND_URL+"/get-setting", 
      {
        globalSettingName,
      }, 
      { withCredentials: true })
      .then((res) => {
        if (res.data != null) {
          console.log(res.data);
          setStrategyAddDetails(res.data);
        }
      });      

  }, [newOrderSubmitted]);

  useEffect(() => {
    if(isws === true) {
        ws.addEventListener("message", (e) => {
          //console.log(e.data); 
          var data = JSON.parse(e.data);
          var global_ltp ="";
          if(data['event'] === 'param_change' && data['action'] === 'reload') {
            setNewOrderSubmitted(true);
            // axios
            // .get(configData.BACKEND_URL+"/strategy", { withCredentials: true })
            // .then((res) => {
            //   if (res.data != null) {
            //     setStrategyData(res.data);
            //     setFilteredData(res.data);
            //   }
            // });
            //notify('Parameter Changed. Reloading!', 'success');
          } else if(data['event'] === 'sell_order_sent') {
              notify('Sell order sent to exchange. Order ID: '+data['order_id']+' Symbol: '+data['instrument_name'], 'success'); 
          } else if(data['event'] === 'buy_order_filled') {
              notify('Buy Order Filled. Order ID: '+data['order_id'], 'success');      
          } else if(data['event'] === 'buy_order_rejected') {
              notify('Buy Order Rejected. Order ID: '+data['order_id'], 'error');               
          } else if(data['event'] === 'buy_order_cancelled') {
              notify('Buy Order Cancelled. Order ID: '+data['order_id'], 'error');               
          } else if(data['event'] === 'sell_order_filled') {
              notify('Sell Order Filled. Qty: '+data['qty']+' Symbol: '+data['symbol'], 'success'); 
          } else if(data['event'] === 'sell_order_rejected') {
              notify('Sell Order Rejected. Order ID: '+data['order_id'], 'error');               
          } else if(data['event'] === 'sell_order_cancelled') {
              notify('Sell Order Cancelled. Order ID: '+data['order_id'], 'error');             
          } else if(data['event'] === 'buy_order_sent') {
              notify('Buy order sent to exchange. Order ID: '+data['order_id']+' Qty: '+data['qty']+' Symbol: '+data['symbol'], 'success');           
          } else if(data['event'] === 'ltp_data') {
      
            
          setLtpData(data['ltp'])          

          if(data['live_pnl']) {
            setLivePnl(data['live_pnl'])
            // console.log(data['live_pnl'])
            // console.log(livePnl)
          } else {
            setLivePnl(0) 
          } 
          if(data['total_pnl']) {
            setTotalPnl(data['total_pnl'])
            // console.log(data['live_pnl'])
            // console.log(livePnl)
          } else {
            setTotalPnl(0) 
          }           
          if(data['stratg_pnl']) {
            setStratgPnl(data['stratg_pnl'])
          }
      }
    });

    ws.addEventListener("open", (e) => {
      console.log("Websocket Opened",e);

    });      

    ws.addEventListener("error", (e) => {
        console.log("Websocket Error",e);
    });

    ws.addEventListener("close", (e) => {
      console.log("Websocket Closed. Reconnecting in 5 seconds!",e);
      //setRetrySocket(true);
    });

  }
}, [newOrderSubmitted]);

  const handleSearchChange = (newFilteredData) => {
    setFilteredData(newFilteredData);
  };

  // console.log("c", strategyData);
  // console.log("f", filteredData);

  const UpdateStrategy = () => {
    axios
      .post(configData.BACKEND_URL+"/updatestrategy",
        {
          strategyDetails,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data === "success") {
          setStrategyDetails({
            id: '',
            name: "",
            indice: "NIFTY",
            order_type: "limit_buy",
            expiry: "current_wk",
            position_type:"buy",   
            entry_level: 0,
            qty: 0,
            tlslx: 0,            
            tlsly: 0,            
            stoploss: 0,
            target: 0,
            stoploss_level: 0,
            trailing_sl: 0,
            target_level: 0,                                     
            strike: 0,
            risk_reward: 1,
            risk_amount: 0,
            risk_measure: 'points',
            sstatus: 0, 
          });
          setNewOrderSubmitted(true);
          setEditButtonPopup(false); 
          var event_data = {'event':'params_reload', 'message':'Strategy Updated'}
          ws.send(JSON.stringify(event_data))           
        }
      });
  };

  const RoundedQuantity = (num, indice) => {
      var temp_qty = 0;
      if(indice === "NIFTY") {
        temp_qty = Math.round(num / 25)  * 25;
        if(temp_qty < 25) temp_qty = 25;
      } else if(indice === "BANKNIFTY") {
        temp_qty = Math.round(num / 15)  * 15;
        if(temp_qty < 15) temp_qty = 25;
      } else if(indice === "FINNIFTY") {
        temp_qty = Math.round(num / 40)  * 40;  
        if(temp_qty < 40) temp_qty = 40;             
      }
      return temp_qty;
  }

  // const CalcParams = (param) => {
  //     console.log(param);
  //     console.log(strategyDetails)
  //     if(param === 'qty') {
  //       var v_qty = strategyDetails.risk_amount / strategyDetails.stoploss; 
  //       setStrategyDetails({
  //         ...strategyDetails,
  //         qty: (RoundedQuantity(v_qty, strategyDetails.indice)),
  //       });
  //     } else if(param === 'risk_amount') {
  //      var v_risk_amount = strategyDetails.stoploss * strategyDetails.qty; 
  //       setStrategyDetails({
  //         ...strategyDetails,
  //         risk_amount: v_risk_amount,
  //       });                
  //     } else if(param === 'stoploss') {
  //       var v_stoploss = strategyDetails.risk_amount / strategyDetails.qty; 
  //       setStrategyDetails({
  //         ...strategyDetails,
  //         stoploss: v_stoploss,
  //       });          
  //     }
  //     //if(lastField !== param) setLastField(param)
  // };

  // const StringifyAll = (l) => {

  // };
  // Calculate Logic 

  const CalcParamsAll = () => {
    console.log(strategyDetails);

    if(strategyDetails.stoploss_level === 0 || strategyDetails.stoploss_level === "0") {  
      console.log('stoploss_level === 0');  
      strategyDetails.stoploss_level = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.entry_level) + parseInt(strategyDetails.stoploss)):(parseInt(strategyDetails.entry_level) - parseInt(strategyDetails.stoploss))
    } else {
      console.log('stoploss_level === 0 else');        
      strategyDetails.stoploss = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.stoploss_level) - parseInt(strategyDetails.entry_level)):(parseInt(strategyDetails.entry_level) - parseInt(strategyDetails.stoploss_level))
    }
    if(strategyDetails.target_level === 0 || strategyDetails.target_level === "0") {  
      console.log('target_level === 0');           
      strategyDetails.target_level = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.entry_level) - parseInt(strategyDetails.target)):(parseInt(strategyDetails.entry_level) + parseInt(strategyDetails.target))                                              
    } else {
      console.log('target_level === 0 else');        
      strategyDetails.target = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.entry_level) - parseInt(strategyDetails.target_level)):(parseInt(strategyDetails.target_level) - parseInt(strategyDetails.entry_level))                                              
    }

    strategyDetails.qty = RoundedQuantity(strategyDetails.risk_amount / strategyDetails.stoploss, strategyDetails.indice);
    strategyDetails.risk_reward = (strategyDetails.target / strategyDetails.stoploss).toFixed(2); 
    strategyDetails.trailing_sl = strategyDetails.stoploss_level       
    console.log(strategyDetails)   
    setStrategyDetails({...strategyDetails});
};

const ResetLevels = () => {
  console.log(strategyDetails);
  strategyDetails.stoploss_level = 0;
  strategyDetails.trailing_sl = 0;
  strategyDetails.target_level = 0;
  console.log(strategyDetails)   
  setStrategyDetails({...strategyDetails});
};

const ResetLevelsAdd = () => {
  console.log(strategyAddDetails);
  strategyAddDetails.stoploss_level = 0;
  strategyAddDetails.trailing_sl = 0;
  strategyAddDetails.target_level = 0;
  console.log(strategyAddDetails)   
  setStrategyAddDetails({...strategyAddDetails});
};

//   const CalcParamsAll_ = () => {
//     console.log(strategyDetails);
//     var v_qty = strategyDetails.risk_amount / strategyDetails.stoploss;
//     v_qty = (RoundedQuantity(v_qty, strategyDetails.indice))
//     var v_risk_amount = strategyDetails.stoploss * v_qty;     
//     //var v_stoploss = v_risk_amount / v_qty;
//     //var target = (risk_reward * v_stoploss)   
    
//     if(parseInt(strategyDetails.stoploss_level) === 0) {  
//       console.log('stoploss_level === 0');  
//       var v_stoploss = v_risk_amount / v_qty;      
//       var stoploss_level = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.entry_level) + parseInt(v_stoploss)):(parseInt(strategyDetails.entry_level) - parseInt(v_stoploss))
//       var v_qty = strategyDetails.risk_amount / v_stoploss;
//       v_qty = (RoundedQuantity(v_qty, strategyDetails.indice))      
//     } else {
//       console.log('stoploss_level === 0 else');        
//       var v_stoploss = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.stoploss_level) - parseInt(strategyDetails.entry_level)):(parseInt(strategyDetails.entry_level) - parseInt(strategyDetails.stoploss_level))
//       var v_qty = strategyDetails.risk_amount / v_stoploss;
//       v_qty = (RoundedQuantity(v_qty, strategyDetails.indice))      
//     }
//     if(parseInt(strategyDetails.target_level) === 0) {  
//       console.log('target_level === 0');           
//       var target_level = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.entry_level) - parseInt(target)):(parseInt(strategyDetails.entry_level) + parseInt(target))                                              
//       var risk_reward = (strategyDetails.target / v_stoploss)      
//     } else {
//       console.log('target_level === 0 else');        
//       var target = (strategyDetails.order_type === 'limit_sell' || strategyDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyDetails.entry_level) - parseInt(strategyDetails.target_level)):(parseInt(strategyDetails.target_level) - parseInt(strategyDetails.entry_level))                                              
//       //var target = (risk_reward * v_stoploss)  
//       var risk_reward = (target / v_stoploss)      
//     }

//     v_risk_amount = v_stoploss * v_qty;  
//     risk_reward = parseFloat(risk_reward).toFixed(2); 

//     setStrategyDetails({
//       ...strategyDetails,
//       qty: parseInt(v_qty),
//       risk_amount: v_risk_amount,  
//       stoploss: parseInt(v_stoploss),   
//       stoploss_level: parseInt(stoploss_level),
//       target_level: parseInt(target_level),
//       target: parseInt(target),
//       risk_reward: risk_reward
//     });
       
//     console.log(strategyDetails)    
//     //if(lastField !== param) setLastField(param)
// };

const viewLog = (row) => {
  console.log('viewLog',row);
  var sid = row.id;
  console.log('viewlog sid',sid);
  axios.get(configData.BACKEND_URL+"/strategylog?sid="+sid, { withCredentials: true }
  ).then((res) => {
    //#if (res.data === "success") {
      const logs = res.data;
      console.log(logs);
      setLogDetails(logs);
      setViewButtonPopup(true);
    //}
});
  
  // setViewButtonPopup(JSON.parse(JSON.stringify(client))); 

  // var v_qty = strategyAddDetails.risk_amount / strategyAddDetails.stoploss;
  // v_qty = (RoundedQuantity(v_qty, strategyAddDetails.indice))
  // var v_risk_amount = strategyAddDetails.stoploss * v_qty;     
  // var v_stoploss = v_risk_amount / v_qty;
  // var risk_reward = (strategyAddDetails.target / v_stoploss)   
  // var target = (risk_reward * v_stoploss)    
  // var stoploss_level = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) + parseInt(v_stoploss)):(parseInt(strategyAddDetails.entry_level) - parseInt(v_stoploss))
  // var target_level = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) - parseInt(target)):(parseInt(strategyAddDetails.entry_level) + parseInt(target))                                              
  // risk_reward = parseFloat(risk_reward).toFixed(2); 

  // setStrategyAddDetails({
  //   ...strategyAddDetails,
  //   qty: v_qty,
  //   risk_amount: v_risk_amount,  
  //   stoploss: v_stoploss,   
  //   stoploss_level: stoploss_level,
  //   target_level: target_level,
  //   target: target,
  //   risk_reward: risk_reward
  // });
     
  // console.log(strategyAddDetails)    
  //if(lastField !== param) setLastField(param)
};

const terminateStrategy = (row) => {
  console.log('terminateStrategy',row);
  var sid = row.id;
  console.log('terminateStrategy sid',sid);
  var event_data = {'event':'terminate_strategy','id':sid, 'message':'Terminate Strategy'}
  if(isws === true) {
    ws.send(JSON.stringify(event_data))
  }
};

const copyStrategy = (row) => {
    console.log('copyStrategy',row);
    //var sid = row.id;
    axios.post(configData.BACKEND_URL+"/copystrategy", { 'strategyDetails': row, }, { withCredentials: true })
    .then((res) => {
      if (res.data === "success") {
        setNewOrderSubmitted(true);
        setEditButtonPopup(true); 
        setStrategyDetails(JSON.parse(JSON.stringify(row)));
        // axios
        // .get(configData.BACKEND_URL+"/strategy", { withCredentials: true })
        // .then((res) => {
        //   if (res.data != null) {
        //     setStrategyData(res.data);
        //     setFilteredData(res.data);
        //     setEditButtonPopup(true); 
        //     setStrategyDetails(JSON.parse(JSON.stringify(row)));
        //   }
        // });
      }
    });
  // console.log('terminateStrategy sid',sid);
  // var event_data = {'event':'terminate_strategy','id':sid, 'message':'Terminate Strategy'}
  // ws.send(JSON.stringify(event_data))
};

const copyAddStrategy = (row) => {
  console.log('copyStrategy',row);
  //var sid = row.id;

  setStrategyAddDetails(JSON.parse(JSON.stringify(row)));
  setButtonPopup(true); 

  // axios.post(configData.BACKEND_URL+"/copystrategy", { 'strategyDetails': row, }, { withCredentials: true })
  // .then((res) => {
  //   if (res.data === "success") {
  //     axios
  //     .get(configData.BACKEND_URL+"/strategy", { withCredentials: true })
  //     .then((res) => {
  //       if (res.data != null) {
  //         setStrategyData(res.data);
  //         setFilteredData(res.data);
  //         setEditButtonPopup(true); 
  //         setStrategyDetails(JSON.parse(JSON.stringify(row)));
  //       }
  //     });
  //   }
  // });
};


const CalcParamsAddAll = () => {
  console.log(strategyAddDetails);
  if(strategyAddDetails.stoploss_level === 0 || strategyAddDetails.stoploss_level === "0") {  
    console.log('stoploss_level === 0');  
    strategyAddDetails.stoploss_level = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) + parseInt(strategyAddDetails.stoploss)):(parseInt(strategyAddDetails.entry_level) - parseInt(strategyAddDetails.stoploss))
  } else {
    console.log('stoploss_level === 0 else');        
    strategyAddDetails.stoploss = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.stoploss_level) - parseInt(strategyAddDetails.entry_level)):(parseInt(strategyAddDetails.entry_level) - parseInt(strategyAddDetails.stoploss_level))
  }
  if(strategyAddDetails.target_level === 0 || strategyAddDetails.target_level === "0") {  
    console.log('target_level === 0');           
    strategyAddDetails.target_level = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) - parseInt(strategyAddDetails.target)):(parseInt(strategyAddDetails.entry_level) + parseInt(strategyAddDetails.target))                                              
  } else {
    console.log('target_level === 0 else');        
    strategyAddDetails.target = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) - parseInt(strategyAddDetails.target_level)):(parseInt(strategyAddDetails.target_level) - parseInt(strategyAddDetails.entry_level))                                              
  }

  strategyAddDetails.qty = RoundedQuantity(strategyAddDetails.risk_amount / strategyAddDetails.stoploss, strategyAddDetails.indice);  
  strategyAddDetails.risk_reward = (strategyAddDetails.target / strategyAddDetails.stoploss).toFixed(2); 
  strategyAddDetails.trailing_sl = strategyAddDetails.stoploss_level
  console.log(strategyAddDetails)   
  setStrategyAddDetails({...strategyAddDetails});
};

// const CalcParamsAddAll = () => {
//   var v_qty = strategyAddDetails.risk_amount / strategyAddDetails.stoploss;
//   v_qty = (RoundedQuantity(v_qty, strategyAddDetails.indice))
//   var v_risk_amount = strategyAddDetails.stoploss * v_qty;     
//   var v_stoploss = v_risk_amount / v_qty;
//   var risk_reward = (strategyAddDetails.target / v_stoploss)   
//   var target = (risk_reward * v_stoploss)    
  
//   if(strategyAddDetails.stoploss_level === 0) {
//     var stoploss_level = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) + parseInt(v_stoploss)):(parseInt(strategyAddDetails.entry_level) - parseInt(v_stoploss))
//   }

//   if(strategyAddDetails.target_level === 0) {  
//     var target_level = (strategyAddDetails.order_type === 'limit_sell' || strategyAddDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyAddDetails.entry_level) - parseInt(target)):(parseInt(strategyAddDetails.entry_level) + parseInt(target))                                              
//   }

//   risk_reward = parseFloat(risk_reward).toFixed(2); 

//   setStrategyAddDetails({
//     ...strategyAddDetails,
//     qty: v_qty,
//     risk_amount: v_risk_amount,  
//     stoploss: v_stoploss,   
//     stoploss_level: stoploss_level,
//     target_level: target_level,
//     target: target,
//     risk_reward: risk_reward
//   });

//   console.log(strategyAddDetails)    
//   //if(lastField !== param) setLastField(param)
// };

  const handleChange = (row, isChecked) => {
    console.log('isChecked',isChecked.target.checked);
    // if(row.sstatus === 1) 
    //   row.sstatus = 0;
    // else
    //   row.sstatus = 1;
    // if(isChecked.target.checked) {
    //   row.sstatus = 1;
    // } else {
    //   row.sstatus = 0;      
    // } 

    //setRecord(JSON.parse(JSON.stringify({id:client.id,sstatus:(client.sstatus===1?0:1)})));

    // console.log('before ',currentRecord);
    // setRecord(JSON.parse(JSON.stringify({id:row.id,sstatus:row.sstatus})));
    // // console.log(JSON.parse(JSON.stringify(row)));
    // console.log('after ',currentRecord);
    console.log(row); 
    // console.log(currentRecord);
    axios.post(configData.BACKEND_URL+"/updatestrategystatus",
        {
          'strategyDetails': row,
        },
        { withCredentials: true }
      ).then((res) => {
        if (res.data === "success") {
          setNewOrderSubmitted(true); 
          var event_data = {'event':'params_reload', 'message':'Strategy Status Updated'}
          ws.send(JSON.stringify(event_data))                        
          // axios
          // .get(configData.BACKEND_URL+"/strategy", { withCredentials: true })
          // .then((res) => {
          //   if (res.data != null) {
          //     setStrategyData(res.data);
          //     setFilteredData(res.data);
          //   }
          // });
        }
      });
  };

  const StrategyTable = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30;
    const totalStrategy = filteredData.length;
    const computedStrategy = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
    const computedStrategyLength = computedStrategy.length;

    return (
      <>
        {" "}

        <div className="tableResultsWrap">
          {" "}
          <div className="resultsSpan">
            Showing <font className="resultsBold"> {computedStrategyLength} </font> of <font className="resultsBold"> {totalStrategy} </font> results
          </div>
          <Pagination total={totalStrategy} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
        </div>
        <div className="orderNav">
              <ul>
                <li className={`${filterActive === 1 ? "active" : ""}`} onClick={() => { setFilterOrders(""); setFilterActive(1); }}>All</li>
                <li className={`${filterActive === 2 ? "active" : ""}`} onClick={() => { setFilterOrders("Waiting"); setFilterActive(2); }}>Waiting</li>
                <li className={`${filterActive === 3 ? "active" : ""}`} onClick={() => { setFilterOrders("Running"); setFilterActive(3); }}>Running</li>                
                <li className={`${filterActive === 4 ? "active" : ""}`} onClick={() => { setFilterOrders("Error"); setFilterActive(4); }}>Error</li>                
                <li className={`${filterActive === 5 ? "active" : ""}`} onClick={() => { setFilterOrders("SLHIT"); setFilterActive(5); }}>SLHIT</li>
                <li className={`${filterActive === 6 ? "active" : ""}`} onClick={() => { setFilterOrders("TGHIT"); setFilterActive(6); }}>TGHIT </li>                
              </ul>
            </div>
            
        <table>
          <thead>
            <tr>
              <th><font className="maincolor">&nbsp;&nbsp;&nbsp;&nbsp;#</font></th>
              <th></th>                               
              <th>Name</th>
              <th>Indice</th>
              <th>OrderType</th>
              <th>Position</th>              
              <th>Entry</th>
              <th>SLLVL</th>
              <th>TLSLV</th>
              <th>TGLVL</th>                            
              <th>Qty</th>
              <th>TLSLX</th>              
              <th>TLSLY</th>              
              <th>SL</th>   
              <th>TG</th>                          
              <th>Strike</th>              
              <th>RR</th>
              <th>Risk</th>
              <th className="st_pnl">PNL</th>
              <th className="st_pnl">Status</th>
              <th></th>                                                            
            </tr>
          </thead>
          <tbody>
            {computedStrategy.map((client, i) => {
              return (
                <tr key={i} className={client.status.toLowerCase()}>
                  <td>
                    <div className={(client.inactive===1?'hide':'')}>
                    {/* <input type="checkbox" checked={(client.sstatus===1?true:false)} onChange={(event) => { setRecord(JSON.parse(JSON.stringify({id:client.id,sstatus:(client.sstatus===1?0:1)})));handleChange(client, event)}} /> */}
                    <Switch checked={(client.sstatus===1?true:false)} onClick={(event) => { handleChange(client, event)}} />
                    </div>
                  </td>
                  <td>{client.status}</td>                   
                  <td>{client.name}</td>
                  <td>{client.indice}</td>
                  <td>{client.order_type}</td>
                  <td>{client.position_type}</td>
                  <td>{client.entry_level}</td>
                  <td>{client.stoploss_level}</td>
                  <td>{client.trailing_sl}</td>                  
                  <td>{client.target_level}</td>                                    
                  <td>{client.qty}</td>                  
                  <td>{client.tlslx}</td>
                  <td>{client.tlsly}</td>
                  <td>{client.stoploss}</td>
                  <td>{client.target}</td>                                    
                  <td>{client.strike}</td>
                  <td>{client.risk_reward}</td>
                  <td>{client.risk_amount}</td> 
                  <td className={"st_pnl "+client.name.toUpperCase()}>
                    <div>
                    {client.sstatus == 0 ? <span>{client.pnl}</span> : ''}
                    {(stratgPnl[client.name] > 0 && client.sstatus == 1) ? <span className="greenbold">{stratgPnl[client.name]}</span> : <span></span> }
                    {(stratgPnl[client.name] < 0 && client.sstatus == 1) ? <span className="redbold">{stratgPnl[client.name]}</span> : <span></span> }        
                    </div>
                    </td>                  
                  <td className="st_pnl">{(client.sstatus===1?'ON':'OFF')}</td>
                  <td className="maincolor">
                    <button className="terminateButton" onClick={() => terminateStrategy(JSON.parse(JSON.stringify(client)))}> <PowerSettingsNewIcon /></button>
                    <button className="editOrderButton" onClick={() => copyAddStrategy(JSON.parse(JSON.stringify(client)))}> <ContentCopyIcon /></button>
                    <button className="editOrderButton" onClick={() => { setEditButtonPopup(true); setStrategyDetails(JSON.parse(JSON.stringify(client))); }}> <EditRoundedIcon /> </button>
                    <button className="editOrderButton" onClick={() => viewLog(JSON.parse(JSON.stringify(client)))}> <ReadMoreRoundedIcon /></button>
                  </td>                                                                                                           
                </tr>
              );
            })}
          </tbody>
        </table>

       

        
      </>
    );
  };

  const AddStrategy = () => {
    // const [strategyAddDetails, setStrategyAddDetails] = useState({
    //   name: "",
    //   indice: "NIFTY",
    //   order_type: "",
    //   entry_level: "",
    //   qty: 0,
    //   stoploss: 0,
    //   target: 0,  
    //   stoploss_level: 0,
    //   target_level: 0,             
    //   strike: 0,
    //   risk_reward: 1,
    //   risk_amount: "",
    //   risk_measure: 'points',      
    //   sstatus: 0,      
    // });

    const addNewStrategy = () => {
      axios
        .post(configData.BACKEND_URL+"/newstrategy",
          {
            strategyAddDetails,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data === "success") {
            setStrategyAddDetails({
              name: "",
              indice: "NIFTY",
              order_type: "limit_buy",
              position_type: "buy",
              entry_level: 0,
              qty: 0,              
              stoploss: 0,
              target: 0,
              tlslx: 0,
              tlsly: 0,
              stoploss_level: 0,
              target_level: 0,              
              strike: 0,
              risk_reward: 1,
              risk_amount: 0,
              risk_measure: 'points',                 
              sstatus: 0, 
            });
            setNewOrderSubmitted(true);
            setButtonPopup(false);
            var event_data = {'event':'params_reload', 'message':'New Strategy Added'}
            ws.send(JSON.stringify(event_data))            
          }
        });
    };


    return ( 
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <div className="popupWrap">
          <div className="productsSummary">
            <h3 className="productSummaryLeft">Add New Strategy</h3>
          </div>

          <div className="addNewOrderWrap">
            <div className="addNewOrderForm">
              <div className="orderDetails">
                <div className="input-group input-group-33">
                  <label>Strategy Name</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyAddDetails.name}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        name: e.target.value,
                      })]
                    }
                    required="required"
                  />
                  </div>
                  <div className="input-group input-group-33">               
                  <label>Indice</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyAddDetails.indice}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        indice: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option>NIFTY</option>
                    <option>BANKNIFTY</option>
                    <option>FINNIFTY</option>
                  </select>
                  </div>
                  <div className="input-group input-group-33-last">               
                  <label>Position Type</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyAddDetails.position_type}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        position_type: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="buy">BUY</option>
                    <option value="sell">SELL</option>
                  </select>
                  </div>                  
                  <div className="input-group input-group-33">  
                  <label>Order Type</label>                
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyAddDetails.order_type}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        order_type: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="limit_buy">LIMIT_BUY</option>
                    <option value="limit_sell">LIMIT_SELL</option>
                    <option value="trigger_buy">TRIGGER_BUY</option>
                    <option value="trigger_sell">TRIGGER_SELL</option>                    
                  </select> 
                  </div>
                  <div className="input-group input-group-33">                  
                <label>Expiry</label> 
                <select
                  className="orderDetailsSelect "
                  placeholder="Expiry To Trade"
                  value={strategyAddDetails.expiry}
                  onChange={(e) =>
                    setStrategyAddDetails({
                      ...strategyAddDetails,
                      expiry: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option value="current_wk">WEEKLY-CURRENT</option>
                  <option value="next_wk">WEEKLY-NEXT</option>
                  <option value="far_next_wk">WEEKLY-FAR NEXT</option>
                  <option value="current_month">MONTHLY-CURRENT</option>
                  <option value="next_month">MONTHLY-NEXT</option>
                  <option value="far_next_month">MONTHLY-FAR NEXT</option>                                    
                </select>
                </div>                      
                  <div className="input-group input-group-33-last">
                  <label>Status</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyAddDetails.sstatus}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        sstatus: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="0">OFF</option>
                    <option value="1">ON</option>                                                                                                                                           
                  </select>
                  </div>
                  <div className="input-group input-group-20">  
                  <label>Entry Level</label>                                                     
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyAddDetails.entry_level}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        entry_level: e.target.value,
                      })]
                    }
                    required="required"
                  />  
                </div>
                <div className="input-group input-group-10">                
                <label>TLSLX</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.tlslx}
                  onChange={(e) => 
                    [setStrategyAddDetails({
                      ...strategyAddDetails,
                      tlslx: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div> 
                <div className="input-group input-group-10">                
                <label>TLSLY</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.tlsly}
                  onChange={(e) => 
                    [setStrategyAddDetails({
                      ...strategyAddDetails,
                      tlsly: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>                                  
                <div className="input-group input-group-20">                
                <label>Stoploss</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.stoploss}
                  onChange={(e) => 
                    [setStrategyAddDetails({
                      ...strategyAddDetails,
                      stoploss: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>           
                <div className="input-group input-group-20">    
                <label>Target</label>            
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.target}
                  onChange={(e) =>
                    [setStrategyAddDetails({
                      ...strategyAddDetails,
                      target: e.target.value,                      
                    })]
                  }
                  required="required"
                />                   
                </div>
                <div className="input-group input-group-20-last">                 
                  <label>Strike</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyAddDetails.strike}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        strike: e.target.value,
                      })]
                    }
                    required="required"
                  >
                  <option value="-10">OTM-10</option>                     
                  <option value="-9">OTM-9</option>  
                  <option value="-8">OTM-8</option>  
                  <option value="-7">OTM-7</option>  
                  <option value="-6">OTM-6</option>  
                  <option value="-5">OTM-5</option>                   
                  <option value="-4">OTM-4</option>  
                  <option value="-3">OTM-3</option>  
                  <option value="-2">OTM-2</option>                                                              
                  <option value="-1">OTM-1</option>                    
                  <option value="0" selected>ATM</option>
                  <option value="1">ITM+1</option>
                  <option value="2">ITM+2</option>
                  <option value="3">ITM+3</option>
                  <option value="4">ITM+4</option> 
                  <option value="5">ITM+5</option>                                        
                  <option value="6">ITM+6</option>                                        
                  <option value="7">ITM+7</option>                                        
                  <option value="8">ITM+8</option>                                        
                  <option value="9">ITM+9</option>                                        
                  <option value="10">ITM+10</option>                                      
                  </select>
                  </div>
                <div className="input-group input-group-20">                
                <label>Stoploss Level</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.stoploss_level}
                  onChange={(e) => 
                    [setStrategyAddDetails({
                      ...strategyAddDetails,
                      stoploss_level: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>
                <div className="input-group input-group-20">                
                <label>TLSL Level</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.trailing_sl}
                  onChange={(e) => 
                    setStrategyDetails({
                      ...strategyAddDetails,
                      trailing_sl: e.target.value,
                    })
                  }
                  required="required"
                />     
                </div>                 
                <div className="input-group input-group-20">    
                <label>Target Level</label>            
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyAddDetails.target_level}
                  onChange={(e) =>
                    [setStrategyAddDetails({
                      ...strategyAddDetails,
                      target_level: e.target.value,
                    })]
                  }
                  required="required"
                />                   
                </div>                  
                  <div className="input-group input-group-20">
                  <label>Quantity</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyAddDetails.qty}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        qty: e.target.value,
                      })]
                    }
                    required="required"
                  />                    

                </div> 
                <div className="input-group input-group-20">
                  <label>Risk Amount</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyAddDetails.risk_amount}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        risk_amount: e.target.value,
                      })]
                    }
                    required="required"
                  />
                  
                </div>                                                 
                  <div className="input-group input-group-20-last">
                  <label>Risk Reward</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyAddDetails.risk_reward}
                    onChange={(e) =>
                      [setStrategyAddDetails({
                        ...strategyAddDetails,
                        risk_reward: e.target.value
                      })]
                    }
                    required="required"
                  />                    
                </div>
              </div>
            </div>
          </div>

          <div className="submitWrap">
            <div className="submitNewOrder">
            <div className="input-group input-group-33">  
              <button
                className="calculateBtn"
                onClick={(e) => CalcParamsAddAll()}>
                <CalculateIcon />               
                <span className="addOrderText">Calculate</span>
              </button>   
              </div>   
              <div className="input-group input-group-33">  
              <button
                className="calculateBtn"
                onClick={(e) => ResetLevelsAdd()}>
                <CalculateIcon />               
                <span className="addOrderText">Reset Levels</span>
              </button>   
              </div>                          
              <div className="input-group input-group-33">               
              <button
                className="submitNewOrderBtn"
                onClick={() => addNewStrategy()}>
                <AddCircleOutlineRoundedIcon />
                <span className="addOrderText">Add</span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );




  };

  return (
    <div className="bodyWrap" key="strategy-list">
      <div className="contentOrderWrap clientsTableWrap">
        <div className="leftSide">
          <table>
          <tr>
          <td width="30%">
          <h1>Strategy</h1>
          </td>
          <td>
          <div>
          {Object.keys(ltpData).map((key, index) => (
              <span><strong>{key}:</strong> <span className="greenbold">{JSON.stringify(ltpData[key])}</span>  </span>
          ))}              
          {livePnl > 0 ? <span> <strong>LIVE PNL:</strong> <span className="greenbold">{livePnl}</span> </span> : <span></span> }
          {livePnl < 0 ? <span> <strong>LIVE PNL:</strong> <span className="redbold">{livePnl}</span> </span> : <span></span> } 
          {totalPnl > 0 ? <span> <strong>TOTAL PNL:</strong> <span className="greenbold">{totalPnl}</span> </span> : <span></span> }
          {totalPnl < 0 ? <span> <strong>TOTAL PNL:</strong> <span className="redbold">{totalPnl}</span> </span> : <span></span> }                    
          </div>
          {/* <div>
          {Object.keys(stratgPnl).map((key, index) => (
              <span><strong>{key}: </strong> <span className="greenbold">{JSON.stringify(stratgPnl[key])}</span>  </span>
          ))}              
          </div>  */}
          </td>
          </tr>
          </table>          
          <div className="orderNavWrap">
            <div className="addOrderWrap">
              <SearchBar data={strategyData} handleSearchChange={handleSearchChange} dataType="strategy" status={filterOrders} />
              <button className="addOrder" onClick={() => { setButtonPopup(true); }}>
                <AddCircleOutlineRoundedIcon />
                <span className="addOrderText">Add</span>
              </button>
            </div>
          </div>
          <div className="orderWrap">
            <StrategyTable />
          </div>
        </div>
      </div>

      {AddStrategy()}

      <Popup trigger={buttonViewPopup} setTrigger={setViewButtonPopup}>
      <div className="popupWrap">
        <div className="productsSummary">
          <h3 className="productSummaryLeft">Strategy Log</h3>
        </div>

        <div className="addNewOrderWrap">
          <div className="addNewOrderForm">
              <table className="logtable">
              <thead>
                <tr>
                <th>ST</th>
                <th>Desc</th>
                <th>Type</th>
                <th>Time</th>
                </tr>
              </thead>
              <tbody>
              {logDetails.map((logDetail, index) => (
                <tr key={index} className={logDetail.type.toLowerCase()}>
                  <td>{logDetail.strategy_name}</td><td>{logDetail.descrp}</td><td>{logDetail.type}</td><td>{logDetail.created.split(".00")[0]}</td>
                </tr>
              ))}   
              </tbody>
              </table> 
          </div>
        </div>
      </div>
    </Popup>

      <Popup trigger={buttonEditPopup} setTrigger={setEditButtonPopup}>
      <div className="popupWrap">
        <div className="productsSummary">
          <h3 className="productSummaryLeft">Edit Strategy</h3>
        </div>

        <div className="addNewOrderWrap">
          <div className="addNewOrderForm">
            <div className="orderDetails">
              <div className="input-group input-group-33">
              <label>Name</label>  
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyDetails.name}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      name: e.target.value,
                    })
                  }
                  required="required"
                />
                </div>
                <div className="input-group input-group-33">                                                  
                <label>Indice</label> 
                <select
                  className="orderDetailsSelect "
                  placeholder="Indice"
                  value={strategyDetails.indice}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      indice: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option>NIFTY</option>
                  <option>BANKNIFTY</option>
                  <option>FINNIFTY</option>
                </select>
                </div>
                <div className="input-group input-group-33-last">               
                  <label>Position Type</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyDetails.position_type}
                    onChange={(e) =>
                      [setStrategyDetails({
                        ...strategyDetails,
                        position_type: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="buy">BUY</option>
                    <option value="sell">SELL</option>
                  </select>
                  </div>                 
                <div className="input-group input-group-33">                  
                <label>Order Type</label> 
                <select
                  className="orderDetailsSelect "
                  placeholder="Order Type"
                  value={strategyDetails.order_type}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      order_type: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option value="limit_buy">LIMIT_BUY</option>
                  <option value="limit_sell">LIMIT_SELL</option>
                  <option value="trigger_buy">TRIGGER_BUY</option>
                  <option value="trigger_sell">TRIGGER_SELL</option>                    
                </select>
                </div>
                <div className="input-group input-group-33">                  
                <label>Expiry</label> 
                <select
                  className="orderDetailsSelect "
                  placeholder="Expiry To Trade"
                  value={strategyDetails.expiry}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      expiry: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option value="current_wk">WEEKLY-CURRENT</option>
                  <option value="next_wk">WEEKLY-NEXT</option>
                  <option value="far_next_wk">WEEKLY-FAR NEXT</option>
                  <option value="current_month">MONTHLY-CURRENT</option>
                  <option value="next_month">MONTHLY-NEXT</option>
                  <option value="far_next_month">MONTHLY-FAR NEXT</option>                                    
                </select>
                </div>                
                <div className="input-group input-group-33-last">  
                <label>Status</label> 
                <select
                  className="orderDetailsSelect"
                  placeholder="Status"
                  value={strategyDetails.sstatus}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      sstatus: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option value="0">OFF</option>
                  <option value="1">ON</option>                                                                                                                                           
                </select> 
              </div>                 
                <div className="input-group input-group-20">  
                <label>Entry Level</label> 
                <input
                  type="text"
                  placeholder="Entry Level"
                  className="orderDetailsInput"
                  value={strategyDetails.entry_level}
                  onChange={(e) => {
                    setStrategyDetails({
                      ...strategyDetails,
                      entry_level: e.target.value,
                    });}
                  }
                  required="required"
                />   
                </div>
                <div className="input-group input-group-10">                
                <label>TLSLX</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyDetails.tlslx}
                  onChange={(e) => 
                    [setStrategyDetails({
                      ...strategyDetails,
                      tlslx: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div> 
                <div className="input-group input-group-10">                
                <label>TLSLY</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyDetails.tlsly}
                  onChange={(e) => 
                    [setStrategyDetails({
                      ...strategyDetails,
                      tlsly: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>                   
                <div className="input-group input-group-20">                  
                <label>Stoploss</label> 
                <input
                  type="text"
                  placeholder="Stoploss"
                  className="orderDetailsInput "
                  value={strategyDetails.stoploss}
                  onChange={(e) => {
                    setStrategyDetails({
                      ...strategyDetails,
                      stoploss: e.target.value,
                    }); }

                  }
                  required="required"
                /> 
                </div>                
                <div className="input-group input-group-20">                      
                <label>Target</label> 
                <input
                  type="text"
                  placeholder="Target"
                  className="orderDetailsInput "
                  value={strategyDetails.target}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      target: e.target.value,
                    
                    })
                  }
                  required="required"
                />
                </div>
               
                <div className="input-group input-group-20">                                    
                <label>Strike</label> 
                <select
                  className="orderDetailsSelect "
                  placeholder="Strike"
                  value={strategyDetails.strike}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      strike: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option value="-10">OTM-10</option>                     
                  <option value="-9">OTM-9</option>  
                  <option value="-8">OTM-8</option>  
                  <option value="-7">OTM-7</option>  
                  <option value="-6">OTM-6</option>  
                  <option value="-5">OTM-5</option>                   
                  <option value="-4">OTM-4</option>  
                  <option value="-3">OTM-3</option>  
                  <option value="-2">OTM-2</option>                                                              
                  <option value="-1">OTM-1</option>                    
                  <option value="0" selected>ATM</option>
                  <option value="1">ITM+1</option>
                  <option value="2">ITM+2</option>
                  <option value="3">ITM+3</option>
                  <option value="4">ITM+4</option> 
                  <option value="5">ITM+5</option>                                        
                  <option value="6">ITM+6</option>                                        
                  <option value="7">ITM+7</option>                                        
                  <option value="8">ITM+8</option>                                        
                  <option value="9">ITM+9</option>                                        
                  <option value="10">ITM+10</option>                                                          
                </select>
                </div>
                <div className="input-group input-group-20">                
                <label>Stoploss Level</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyDetails.stoploss_level}
                  onChange={(e) => 
                    setStrategyDetails({
                      ...strategyDetails,
                      stoploss_level: e.target.value,
                    })
                  }
                  required="required"
                />     
                </div>
                <div className="input-group input-group-20">                
                <label>TLSL Level</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyDetails.trailing_sl}
                  onChange={(e) => 
                    setStrategyDetails({
                      ...strategyDetails,
                      trailing_sl: e.target.value,
                    })
                  }
                  required="required"
                />     
                </div>                
                <div className="input-group input-group-20">    
                <label>Target Level</label>            
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyDetails.target_level}
                  onChange={(e) =>
                    setStrategyDetails({
                      ...strategyDetails,
                      target_level: e.target.value,
                    })
                  }
                  required="required"
                />                   
                </div>                 
                <div className="input-group input-group-20">                  
                <label>Quantity</label> 
                <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput "
                    value={strategyDetails.qty}
                    onChange={(e) => {
                      setStrategyDetails({
                        ...strategyDetails,
                        qty: e.target.value,
                    
                      }); }
                    }
                    required="required"
                  />    

                </div>    
                <div className="input-group input-group-20">  
                <label>Risk Amount</label>                 
                <input
                  type="text"
                  placeholder="Risk Amount"
                  className="orderDetailsInput"
                  value={strategyDetails.risk_amount}
                  onChange={(e) => {
                    setStrategyDetails({
                      ...strategyDetails,
                      risk_amount: e.target.value,
                    }); }
                  }
                  required="required"
                />
                </div>                                        
                <div className="input-group input-group-20-last">                  
                <label>Risk Reward</label> 
                <input
                    type="text"
                    placeholder="Risk Reward"
                    className="orderDetailsInput "
                    value={strategyDetails.risk_reward}
                    onChange={(e) =>
                      setStrategyDetails({
                        ...strategyDetails,
                        risk_reward: e.target.value,
                      })
                    }
                    required="required"
                  />    

                </div>
               

            </div>
          </div>
        </div>

        <div className="submitWrap">
          <div className="submitNewOrder">
              <div className="input-group input-group-33">  
              <button
                className="calculateBtn"
                onClick={(e) => CalcParamsAll()}>
                <CalculateIcon />               
                <span className="addOrderText">Calculate</span>
              </button>   
              </div> 
              <div className="input-group input-group-33">  
              <button
                className="calculateBtn"
                onClick={(e) => ResetLevels()}>
                <CalculateIcon />               
                <span className="addOrderText">Reset Levels</span>
              </button>   
              </div>                             
              <div className="input-group input-group-33">                       
              <button
                className="submitNewOrderBtn"
                onClick={() => [CalcParamsAll(),UpdateStrategy()]}>
                <EditRoundedIcon />
                <span className="addOrderText">Update</span>
              </button>
              </div>                        
          </div>
        </div>
      </div>
    </Popup>
      <div>
        <ToastContainer /> 
      </div>
    </div>
  );
}

export default Strategy;
