//import React, { useEffect, useState, useContext } from "react";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Popup from "./../Popup";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SettingsIcon from '@mui/icons-material/Settings';
import "./../Styles/usersSetting.css";
import { AuthLoginInfo } from './../../AuthComponents/AuthLogin';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configData from "./../../Pages/config.json";

function formatIsoDate(date) {
  return date.split("T")[0];
}

function UsersSetting() {
  const [usersData, setUsersData] = useState();
  const [userData, setUserData] = useState();  
  const [usersUpdated, setUsersUpdated] = useState(false);
  const [popup, setPopup] = useState({
    show: false,
    id: null,
  });
  const [newUserPopup, setNewUserPopup] = useState(false);
  const [newOrderSubmitted, setNewOrderSubmitted] = useState(false);  
  const [changeUserPopup, setChangeUserPopup] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);  
  const [currentUser, setCurrentUser] = useState({
    id: "",
    username: "",
    password: "",
    role: "",
    dateCreated: ""
  });  
  const [strategyGlobalDetails, setStrategyGlobalDetails] = useState({
    name: "",
    indice: "NIFTY",
    order_type: "limit_buy",
    position_type: "buy",
    expiry: "current_wk",    
    entry_level: 0,
    qty: 0,              
    tlslx: 0,
    tlsly: 0,
    stoploss: 0,
    target: 0,
    stoploss_level: 0,
    trailing_sl: 0,
    target_level: 0,              
    strike: 0,
    risk_reward: 1,
    risk_amount: 0,
    risk_measure: 'points',                 
    sstatus: 0, 
  });

  const toastId = React.useRef(null);
  const customId = "toast-main";
  const globalSettingName = 'global-setting'

  const user = useContext(AuthLoginInfo);
  
  console.log(user);
  
  // const user = useContext(AuthLoginInfo);
  // setCurrentUser(user);  

  // useEffect(() => {
  //   axios
  //     .get(`http://localhost:5000/getusers`, { withCredentials: true })
  //     .then((res) => {
  //       if (res.data != null) {
  //         setUsersUpdated(false);
  //         setUsersData(res.data);
  //       }
  //     });
  // }, [usersUpdated]);

  useEffect(() => {
    user.password = user.password?'':'';
    setCurrentUser(user); 
    axios
    .post(configData.BACKEND_URL+"/get-setting", 
    {
      globalSettingName,
    }, 
    { withCredentials: true })
    .then((res) => {
      if (res.data != null) {
        console.log(res.data);
        setStrategyGlobalDetails(res.data);
      }
    });
  }, []);
 

  const handleDelete = (id) => {
    setPopup({
      show: true,
      id,
    });
  };

  const notify = (toast_mess) => {
    //if(! toast.isActive(toastId.current)) {
      toastId.current = toast.success(toast_mess, {
        position: "top-right",
        theme: "dark",
        toastId: customId,
        autoClose : 1000,
        newestOnTop: true
      });
  }

  const deleteUserById = (userId) => {
    axios
      .post(configData.BACKEND_URL+"/deleteuser",
        {
          userId,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data === "success") {
          setUsersUpdated(true);
          setPopup({
            show: false,
            id: null,
          });
        }
      });
  };

  const AddNewUserSection = () => {
    return (
      <div className="addNewUserWrap">
        <button className="addOrder" onClick={() => { setButtonPopup(true); }}> <SettingsIcon /> Strategy Global Settings </button>&nbsp;&nbsp;        
        <button className="addOrder" onClick={() => { setChangeUserPopup(true); }}> <AccountCircleRoundedIcon /> Change Password </button>        
      </div>
    );
  };

  const RoundedQuantity = (num, indice) => {
    var temp_qty = 0;
    if(indice === "NIFTY") {
      temp_qty = Math.round(num / 25)  * 25;
      if(temp_qty < 25) temp_qty = 25;
    } else if(indice === "BANKNIFTY") {
      temp_qty = Math.round(num / 15)  * 15;
      if(temp_qty < 15) temp_qty = 25;
    } else if(indice === "FINNIFTY") {
      temp_qty = Math.round(num / 40)  * 40;  
      if(temp_qty < 40) temp_qty = 40;             
    }
    return temp_qty;
}  

const CalcParamsAddAll = () => {
  var v_qty = strategyGlobalDetails.risk_amount / strategyGlobalDetails.stoploss;
  v_qty = (RoundedQuantity(v_qty, strategyGlobalDetails.indice))
  var v_risk_amount = strategyGlobalDetails.stoploss * v_qty;     
  var v_stoploss = v_risk_amount / v_qty;
  var risk_reward = (strategyGlobalDetails.target / v_stoploss)   
  var target = (risk_reward * v_stoploss)    
  var stoploss_level = (strategyGlobalDetails.order_type === 'limit_sell' || strategyGlobalDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyGlobalDetails.entry_level) + parseInt(v_stoploss)):(parseInt(strategyGlobalDetails.entry_level) - parseInt(v_stoploss))
  var target_level = (strategyGlobalDetails.order_type === 'limit_sell' || strategyGlobalDetails.order_type === 'trigger_sell' ) ? (parseInt(strategyGlobalDetails.entry_level) - parseInt(target)):(parseInt(strategyGlobalDetails.entry_level) + parseInt(target))                                              
  risk_reward = parseFloat(risk_reward).toFixed(2); 

  setStrategyGlobalDetails({
    ...strategyGlobalDetails,
    qty: v_qty,
    risk_amount: v_risk_amount,  
    stoploss: v_stoploss,   
    stoploss_level: stoploss_level,
    trailing_sl: trailing_sl,
    target_level: target_level,
    target: target,
    risk_reward: risk_reward
  });
     
  console.log(strategyGlobalDetails)    
  //if(lastField !== param) setLastField(param)
};


  const UsersDataTable = (props) => {
    const filteredUsers = usersData?.filter((user) => {
      if (props.isAdmin) {
        return user.role === "admin";
      } else {
        return user.role !== "admin";
      }
    });

    return filteredUsers !== undefined ? (
      filteredUsers?.map((user, index) => {
        return (
          <tr key={user.id} className={index % 2 != 0 ? "darkerTableBg" : ""}>
            <td className="alignCenter">
              <AccountCircleRoundedIcon className="maincolor" />
            </td>
            <td>{user.username}</td>
            <td className="alignCenter">{formatIsoDate(user.dateCreated)}</td>
            <td className="alignCenter">
              <DeleteForeverRoundedIcon
                className="clickable"
                onClick={() => {
                  handleDelete(user.id);
                }}
              />
            </td>
          </tr>
        );
      })
    ) : (
      <tr></tr>
    );
  };

  const NormalUsers = () => {
    return (
      <div className="usersColumn">
        <div className="adminUsersTable">
          <table className="usersTable normalUsersTable">
            <thead>
              <tr>
                <th className="alignCenter"></th>
                <th className="alignCenter">Name</th>
                <th className="alignCenter">Data created</th>
                <th className="alignCenter"></th>
              </tr>
            </thead>
            <tbody>{<UsersDataTable isAdmin={false} />}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const HandleDeletePopup = () => {
    const handleDeleteFalse = () => {
      setPopup({
        show: false,
        id: null,
      });
    };
    return (
      
      <Popup trigger={popup.show} setTrigger={setPopup}>
        <div className="popupWrap">
          <h3>Are you sure you want to delete this user?</h3>
          <div className="handleDeleteWrap">
            <button
              className="handleDeleteButton"
              onClick={() => {
                deleteUserById(popup.id);
              }}
            >
              Yes
            </button>
            <button
              className="handleDeleteButton"
              onClick={() => {
                handleDeleteFalse();
              }}
            >
              No
            </button>
          </div>
        </div>
      </Popup>
    );
  };

  const ChangeUserPasswordPopup = () => {

    const UpdateUser = () => {
      axios
        .post(
          configData.BACKEND_URL+"/updateuser",
          {
            currentUser,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data === "success") {
            // currentUser({
            //   username: "",
            //   password: "",
            //   role: "",
            // });
            // setUsersUpdated(true);
            setChangeUserPopup(false);
            notify('Password Updated!');
          }
        });
    };

    return (
      <Popup trigger={changeUserPopup} setTrigger={setChangeUserPopup}>
        <div className="popupWrap">
          <div className="productsSummary">
            <h3 className="productSummaryLeft">Change Password</h3>
          </div>

          <div className="addNewOrderWrap">
            <div className="addNewOrderForm">
              <div className="orderDetails">
                <br />
                <div className="input-group">
                  <input type="hidden" placeholder="ID" className="orderDetailsInput orderDetailsInputHalf" value={currentUser.id?currentUser.id:''}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        id: e.target.value,
                      })
                    } required="required"  />                  
                  <input type="text" placeholder="Username" className="orderDetailsInput orderDetailsInputHalf" value={currentUser.username?currentUser.username:''}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        username: e.target.value,
                      })
                    } readOnly required="required" />
                  <input type="text" placeholder="Password" className="orderDetailsInput orderDetailsInputHalf" value={currentUser.password}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        password: e.target.value,
                      })
                    }                  
                    autoFocus required="required" />
                  <p>Enter new password</p>
                </div>

              </div>
            </div>
          </div>

          <div className="submitWrap">
            <div className="submitNewOrder">
              <button className="submitNewOrderBtn" onClick={() => UpdateUser()}>
                <AddCircleOutlineRoundedIcon />
                <span className="addOrderText">Update</span>
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };  

  const AddStrategy = () => {
    // const [strategyGlobalDetails, setStrategyGlobalDetails] = useState({
    //   name: "",
    //   indice: "NIFTY",
    //   order_type: "",
    //   entry_level: "",
    //   qty: 0,
    //   stoploss: 0,
    //   target: 0,  
    //   stoploss_level: 0,
    //   target_level: 0,             
    //   strike: 0,
    //   risk_reward: 1,
    //   risk_amount: "",
    //   risk_measure: 'points',      
    //   sstatus: 0,      
    // });

    const addNewStrategy = () => {
      axios
        .post(configData.BACKEND_URL+"/update-global-setting",
          {
            strategyGlobalDetails,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data === "success") {
            //setStrategyGlobalDetails(setStrategyGlobalDetails);
            setButtonPopup(false);
            setNewOrderSubmitted(true);
            notify('Settings Updated!');
          }
        });
    };


    return ( 
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <div className="popupWrap">
          <div className="productsSummary">
            <h3 className="productSummaryLeft">Global Strategy Settings</h3>
          </div>

          <div className="addNewOrderWrap">
            <div className="addNewOrderForm">
              <div className="orderDetails">

                  <div className="input-group input-group-20">               
                  <label>Indice</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyGlobalDetails.indice}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        indice: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option>NIFTY</option>
                    <option>BANKNIFTY</option>
                    <option>FINNIFTY</option>
                  </select>
                  </div>
                  <div className="input-group input-group-20">  
                  <label>Order Type</label>                
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyGlobalDetails.order_type}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        order_type: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="limit_buy">LIMIT_BUY</option>
                    <option value="limit_sell">LIMIT_SELL</option>
                    <option value="trigger_buy">TRIGGER_BUY</option>
                    <option value="trigger_sell">TRIGGER_SELL</option>                    
                  </select> 
                  </div>
                  <div className="input-group input-group-20">  
                  <label>Position Type</label>                
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyGlobalDetails.position_type}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        position_type: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="buy">BUY</option>
                    <option value="sell">SELL</option>
                  </select> 
                  </div>                  
                  <div className="input-group input-group-20">                  
                <label>Expiry</label> 
                <select
                  className="orderDetailsSelect "
                  placeholder="Expiry To Trade"
                  value={strategyGlobalDetails.expiry}
                  onChange={(e) =>
                    setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      expiry: e.target.value,
                    })
                  }
                  required="required"
                >
                  <option value="current_wk">WEEKLY-CURRENT</option>
                  <option value="next_wk">WEEKLY-NEXT</option>
                  <option value="far_next_wk">WEEKLY-FAR NEXT</option>
                  <option value="current_month">MONTHLY-CURRENT</option>
                  <option value="next_month">MONTHLY-NEXT</option>
                  <option value="far_next_month">MONTHLY-FAR NEXT</option>                                    
                </select>
                </div>                      
                  <div className="input-group input-group-20-last">
                  <label>Status</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyGlobalDetails.sstatus}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        sstatus: e.target.value,
                      })]
                    }
                    required="required"
                  >
                    <option value="0">OFF</option>
                    <option value="1">ON</option>                                                                                                                                           
                  </select>
                  </div>
                  <div className="input-group input-group-20">  
                  <label>Entry Level</label>                                                     
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyGlobalDetails.entry_level}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        entry_level: e.target.value,
                      })]
                    }
                    required="required"
                  />  
                </div>
                <div className="input-group input-group-10">                
                <label>TLSLX</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.tlslx}
                  onChange={(e) => 
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      tlslx: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>  
                <div className="input-group input-group-10">                
                <label>TLSLY</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.tlsly}
                  onChange={(e) => 
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      tlsly: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>                                 
                <div className="input-group input-group-20">                
                <label>Stoploss</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.stoploss}
                  onChange={(e) => 
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      stoploss: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>           
                <div className="input-group input-group-20">    
                <label>Target</label>            
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.target}
                  onChange={(e) =>
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      target: e.target.value,                      
                    })]
                  }
                  required="required"
                />                   
                </div>
                <div className="input-group input-group-20-last">                 
                  <label>Strike</label>
                  <select
                    className="orderDetailsSelect"
                    placeholder=""
                    value={strategyGlobalDetails.strike}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        strike: e.target.value,
                      })]
                    }
                    required="required"
                  >
                  <option value="-10">OTM-10</option>                     
                  <option value="-9">OTM-9</option>  
                  <option value="-8">OTM-8</option>  
                  <option value="-7">OTM-7</option>  
                  <option value="-6">OTM-6</option>  
                  <option value="-5">OTM-5</option>                   
                  <option value="-4">OTM-4</option>  
                  <option value="-3">OTM-3</option>  
                  <option value="-2">OTM-2</option>                                                              
                  <option value="-1">OTM-1</option>                    
                  <option value="0" selected>ATM</option>
                  <option value="1">ITM+1</option>
                  <option value="2">ITM+2</option>
                  <option value="3">ITM+3</option>
                  <option value="4">ITM+4</option> 
                  <option value="5">ITM+5</option>                                        
                  <option value="6">ITM+6</option>                                        
                  <option value="7">ITM+7</option>                                        
                  <option value="8">ITM+8</option>                                        
                  <option value="9">ITM+9</option>                                        
                  <option value="10">ITM+10</option>                                      
                  </select>
                  </div>
                <div className="input-group input-group-20">                
                <label>Stoploss Level</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.stoploss_level}
                  onChange={(e) => 
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      stoploss_level: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>
                <div className="input-group input-group-20">                
                <label>Trailing SL</label>
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.trailing_sl}
                  onChange={(e) => 
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      trailing_sl: e.target.value,
                    })]
                  }
                  required="required"
                />     
                </div>                
                <div className="input-group input-group-20">    
                <label>Target Level</label>            
                <input
                  type="text"
                  placeholder=""
                  className="orderDetailsInput"
                  value={strategyGlobalDetails.target_level}
                  onChange={(e) =>
                    [setStrategyGlobalDetails({
                      ...strategyGlobalDetails,
                      target_level: e.target.value,
                    })]
                  }
                  required="required"
                />                   
                </div>                  
                  <div className="input-group input-group-20">
                  <label>Quantity</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyGlobalDetails.qty}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        qty: e.target.value,
                      })]
                    }
                    required="required"
                  />                    

                </div> 
                <div className="input-group input-group-20">
                  <label>Risk Amount</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyGlobalDetails.risk_amount}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        risk_amount: e.target.value,
                      })]
                    }
                    required="required"
                  />
                  
                </div>                                                 
                  <div className="input-group input-group-20-last">
                  <label>Risk Reward</label>
                  <input
                    type="text"
                    placeholder=""
                    className="orderDetailsInput"
                    value={strategyGlobalDetails.risk_reward}
                    onChange={(e) =>
                      [setStrategyGlobalDetails({
                        ...strategyGlobalDetails,
                        risk_reward: e.target.value
                      })]
                    }
                    required="required"
                  />                    

                </div>

                

              </div>
            </div>
          </div>

          <div className="submitWrap">
            <div className="submitNewOrder">
            <div className="input-group input-group-50">  
            <button
                className="calculateBtn"
                onClick={(e) => CalcParamsAddAll()}>
                <CalculateIcon />               
                <span className="addOrderText">Calculate</span>
              </button> 
              </div>               
              <div className="input-group input-group-50">               
              <button
                className="submitNewOrderBtn"
                onClick={() => addNewStrategy()}>
                <AddCircleOutlineRoundedIcon />
                <span className="addOrderText">Update</span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  // const AddNewUserPopup = () => {
  //   const [userDetails, setUserDetails] = useState({
  //     username: "",
  //     password: "",
  //     role: "",
  //   });

  //   const addNewUser = () => {
  //     axios
  //       .post(
  //         configData.BACKEND_URL+"/newuser",
  //         {
  //           userDetails,
  //         },
  //         { withCredentials: true }
  //       )
  //       .then((res) => {
  //         if (res.data === "success") {
  //           setUserDetails({
  //             username: "",
  //             password: "",
  //             role: "",
  //           });
  //           setUsersUpdated(true);
  //           setNewUserPopup(false);
  //         }
  //       });
  //   };
  //   return (
  //     <Popup trigger={newUserPopup} setTrigger={setNewUserPopup}>
  //       <div className="popupWrap">
  //         <div className="productsSummary">
  //           <h3 className="productSummaryLeft">Add New User</h3>
  //         </div>

  //         <div className="addNewOrderWrap">
  //           <div className="addNewOrderForm">
  //             <div className="orderDetails">
  //               <div className="input-group">
  //                 <input
  //                   type="text"
  //                   placeholder="Username"
  //                   className="orderDetailsInput orderDetailsInputHalf"
  //                   value={userDetails.username}
  //                   onChange={(e) =>
  //                     setUserDetails({
  //                       ...userDetails,
  //                       username: e.target.value,
  //                     })
  //                   }
  //                   required="required"
  //                 />
  //                 <input
  //                   type="password"
  //                   placeholder="Password"
  //                   className="orderDetailsInput orderDetailsInputHalf"
  //                   value={userDetails.password}
  //                   onChange={(e) =>
  //                     setUserDetails({
  //                       ...userDetails,
  //                       password: e.target.value,
  //                     })
  //                   }
  //                   required="required"
  //                 />
  //               </div>
  //               <div className="input-group">
  //                 <div className="usersInfoText">
  //                   If you want to user be an admin type 'admin' below.
  //                 </div>
  //                 <input
  //                   type="text"
  //                   placeholder="User role"
  //                   className="orderDetailsInput"
  //                   value={userDetails.role}
  //                   onChange={(e) =>
  //                     setUserDetails({
  //                       ...userDetails,
  //                       role: e.target.value,
  //                     })
  //                   }
  //                   required="required"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="submitWrap">
  //           <div className="submitNewOrder">
  //             <button
  //               className="submitNewOrderBtn"
  //               onClick={() => addNewUser()}
  //             >
  //               <AddCircleOutlineRoundedIcon />
  //               <span className="addOrderText">Add</span>
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </Popup>
  //   );
  // };

  return (
    <div className="usersSettingWrap">
      <AddNewUserSection />

      <NormalUsers />

      <HandleDeletePopup />
      {AddStrategy()}
      <ChangeUserPasswordPopup />
      <div>
        <ToastContainer /> 
      </div>      
    </div>
  );
}

export default UsersSetting;
